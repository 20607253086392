@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}

@layer base {
  body {
    @apply bg-bgColor dark:bg-bgDark duration-500 ease-in-out transition-all transform;
  }
}
@layer components {
  .soft-hover {
    @apply dark:text-white p-1 hover:shadow-indigo-300 tracking-wider  hover:text-indigo-700 dark:hover:text-indigo-400 hover:cursor-pointer duration-500 ease-in-out transition-all transform;
  }

  .soft-menu {
    @apply px-3 py-2 rounded-full shadow-sm hover:shadow-indigo-300 tracking-wider  hover:text-indigo-700 dark:hover:text-indigo-400 hover:cursor-pointer duration-500 ease-in-out transition-all transform;
  }
  .soft-animation {
    @apply duration-300 ease-in-out transition-all transform;
  }
}

/*
px-3 py-1 rounded-full shadow-sm hover:shadow-indigo-300 tracking-wider  hover:text-indigo-700 dark:hover:text-indigo-400 hover:cursor-pointer duration-500 ease-in-out transition-all transform

*/
