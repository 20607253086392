.wrap {
  width: 100%;
  /* position: absolute;
  top: 20%; */
}
.spinner-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.spinner {
  height: 100px;
  width: 100px;
  display: inline-block;
  margin: -50px auto 0;
  animation: spin 1s steps(12, end) infinite;
}

.spinner {
  margin-right: 25px;
  margin-top: 25px;
}

.spinner i {
  height: 30px;
  width: 6px;
  margin-left: -3px;
  display: block;
  transition: height 1s;
  position: absolute;
  left: 50%;
  transform-origin: center 50px;
  background: #ff0000;
  box-shadow: 0 0 3px rgba(255, 0, 0, 0.7);
  border-radius: 3px;
}

.spinner:nth-child(2) i {
  height: 30px;
}

.spinner:hover i {
  height: 6px;
}

.spinner:hover:nth-child(2) i {
  height: 6px;
}

.spinner i:nth-child(1) {
  opacity: 0.08;
}
.spinner i:nth-child(2) {
  transform: rotate(30deg);
  opacity: 0.167;
}
.spinner i:nth-child(3) {
  transform: rotate(60deg);
  opacity: 0.25;
}
.spinner i:nth-child(4) {
  transform: rotate(90deg);
  opacity: 0.33;
}
.spinner i:nth-child(5) {
  transform: rotate(120deg);
  opacity: 0.4167;
}
.spinner i:nth-child(6) {
  transform: rotate(150deg);
  opacity: 0.5;
}
.spinner i:nth-child(7) {
  transform: rotate(180deg);
  opacity: 0.583;
}
.spinner i:nth-child(8) {
  transform: rotate(210deg);
  opacity: 0.67;
}
.spinner i:nth-child(9) {
  transform: rotate(240deg);
  opacity: 0.75;
}
.spinner i:nth-child(10) {
  transform: rotate(270deg);
  opacity: 0.833;
}
.spinner i:nth-child(11) {
  transform: rotate(300deg);
  opacity: 0.9167;
}
.spinner i:nth-child(12) {
  transform: rotate(330deg);
  opacity: 1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
